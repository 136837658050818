export const ragas = [
'Shankarabharana',
'Kambhoji',
'Kalyani',
'Todi',
'Bhairavi',
'Mohana',
'Ragamalikas',
'Anandabhairavi',
'Mukhari',
'Kamavardhini',
'Madhyamavati',
'Dhanyasi',
'Nadanamakriya',
'Surati',
'Kedaragaula',
'Khamas',
'Kapi',
'Begada',
'Saveri',
'Saurashtra',
'Kharaharapriya',
'Nata',
'Athana',
'Behag',
'Yadukulakambhoji',
'Purvikalyani',
'Shri',
'Cenjuruti',
'Sahana',
'Hindola',
'Ahiri',
'Shanmukhapriya',
'Sindhu bhairavi',
'Kanada',
'Punnagavarali',
'Bilahari',
'Natakuranji',
'Nilambari',
'Bauli',
'Hamsadhvani',
'Mayamalavagaula',
'Lalita',
'Varali',
'Abhogi',
'Purvi',
'Samanta',
'Arabhi',
'Sama',
'Bhupala',
'Kannada',
'Devagandhari',
'Huseni',
'Simhendramadhyam',
'Gaula',
'Kiravani',
'Asaveri',
'Yamunakalyani',
'Cakravaka',
'Shriranjani',
'Saranga',
'Darbar',
'Vasanta',
'Harikambhoji',
'Kuntalavarali',
'Ritigaula',
'Carukesi',
'Padi',
'Paraju',
'Shubhapantuvarali',
'Gaurimanohari',
'Ramakriya',
'Vacaspati',
'Shuddadhanyasi',
'Bageshri',
'Kedara',
'Navaroj',
'Hamirkalyani',
'Des',
'Amrtavarshini',
'Ranjani',
'Malayamaruta',
'Mand',
'Hindustanikapi',
'Revati',
'Dharmavati',
'Salanganata',
'Kurinji',
'Abheri',
'Bhimpalas',
'Gaulipantu',
'Valaji',
'Dvijavanti',
'Sarasvati',
'Revagupti',
'Manirangu',
'Dvamanohari',
'Hamsanada',
'Mandari',
'Natabhairavi',
'Tilang',
'Gowri Manohari',
'Latangi'
]